<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false">
    <nz-page-header-subtitle>
      <app-breadcrumb [label]="'breadcrumbI18nKey'"> </app-breadcrumb>
    </nz-page-header-subtitle>
    <nz-page-header-extra>
      <div class="usermenu" nz-col nzFlex="200px">
        <ul nz-menu nzMode="horizontal" class="header-menu align-right" style="float: right">
          <li nz-menu-item class="notifications">
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="popTranslation" [nzPlacement]="'bottomRight'">
              <nz-badge>
                <i nz-icon nzType="translation" theme="outline"></i>
              </nz-badge>
            </a>
            <nz-dropdown-menu #popTranslation="nzDropdownMenu">
              <div nz-menu class="pop-notification">
                <div class="p-15 ph-25 border-bottom d-flex justify-content-between align-items-center">
                  <p class="text-dark font-weight-semibold m-b-0">
                    <i nz-icon nzType="translation" theme="outline"></i>
                    <span class="m-l-10">Translations</span>
                  </p>
                </div>
                <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                  <nz-list
                    class="ant-list-item-links"
                    [nzDataSource]="translations"
                    [nzRenderItem]="item"
                    [nzItemLayout]="'horizontal'"
                  >
                    <ng-template #item let-item>
                      <nz-list-item>
                        <a (click)="onChangeTranslation(item)">
                          <div class="d-flex">
                            <!-- <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar> -->
                            <div class="m-l-15">
                              <p class="m-b-0 text-dark">{{ item.name }}</p>
                            </div>
                          </div>
                        </a>
                      </nz-list-item>
                    </ng-template>
                  </nz-list>
                </perfect-scrollbar>
              </div>
            </nz-dropdown-menu>
          </li>
          <li *ngIf="user" nz-menu-item>
            <div>
              <span
                class="p-h-10 pointer"
                nz-dropdown
                [nzDropdownMenu]="profile"
                [nzTrigger]="'click'"
                [nzPlacement]="'bottomRight'"
              >
                <nz-avatar [nzText]="user?.firstName.charAt(0)"> </nz-avatar>
                <span class="pl-3 user-name">{{ user?.firstName }} {{ user?.lastName }}</span>
                (<span *ngIf="user?.roles"
                  ><span *ngIf="user?.roles.length > 0"
                    ><span class="pl-3 roles" *ngFor="let role of user?.roles">{{ role?.name }}</span></span
                  ><span *ngIf="user?.roles.length === 0">no role</span></span
                >)
              </span>
            </div>
            <nz-dropdown-menu #profile="nzDropdownMenu">
              <ul nz-menu class="p-b-15 p-h-10 p-t-20">
                <li class="p-l-20 p-r-20 p-b-15 m-b-10 border-bottom">
                  <div nz-row>
                    <div nz-col>
                      <nz-avatar [nzShape]="'square'" [nzSize]="48" [nzText]="user?.firstName.charAt(0)"></nz-avatar>
                    </div>
                    <div nz-col nzFlex="auto">
                      <div class="m-l-10">
                        <p class="m-b-0 text-dark font-weight-semibold">{{ user?.firstName }} {{ user?.lastName }}</p>
                        <p class="m-b-0 opacity-07">{{ user.email }}</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li nz-menu-item class="p-h-10">
                  <a (click)="showChangePasswordModal()" class="p-v-5 p-h-10">
                    <div nz-row>
                      <div nz-col>
                        <i class="opacity-04 font-size-16" nz-icon nzType="key" theme="outline"></i>
                      </div>
                      <div nz-col nzFlex="auto">
                        <span class="m-l-10">{{ 'card.changePassword' | translate }}</span>
                      </div>
                      <div nz-col>
                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                      </div>
                    </div>
                  </a>
                </li>
                <li nz-menu-item>
                  <a (click)="editUserProfile()" class="p-v-5 p-h-10">
                    <div nz-row>
                      <div nz-col>
                        <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                      </div>
                      <div nz-col nzFlex="auto">
                        <span class="m-l-10">{{ 'card.profile' | translate }}</span>
                      </div>
                      <div nz-col>
                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                      </div>
                    </div>
                  </a>
                </li>
                <li nz-menu-item>
                  <a (click)="logout()" class="p-v-5 d-flex align-items-center justify-content-between">
                    <div nz-row>
                      <div nz-col><i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i></div>
                      <div nz-col nzFlex="auto">
                        <span class="m-l-10">{{ 'card.logout' | translate }}</span>
                      </div>
                      <div nz-col><i class="font-size-10" nz-icon nzType="right" theme="outline"></i></div>
                    </div>
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </li>
        </ul>
      </div>
    </nz-page-header-extra>
    <nz-page-header-content> </nz-page-header-content>
  </nz-page-header>
</div>

<nz-modal
  [(nzVisible)]="changePasswordModal"
  nzTitle="Change your password"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="clickChangePassword()"
>
  <app-form
    [isLoading]="isLoading"
    [showSubmitButton]="false"
    [showCancelButton]="false"
    [loadingMessage]="loadingMessage"
    (submitForm)="changePassword($event)"
    [form]="changePasswordForm"
  ></app-form>
  <!--  <div class="button-div">-->
  <!--    <button nz-button nzType="default" class="cancel-button" (click)="handleCancel()">Cancel</button>-->
  <!--    <button nz-button nzType="primary" class="modal-button" (click)="clickChangePassword()">Change Password</button>-->
  <!--  </div>-->
</nz-modal>
