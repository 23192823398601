<nz-layout>
  <nz-content>
    <div class="form-container">
      <div class="app-logo">
        <img src="../../../assets/images/logo.svg" />
        <h1 translate>MHIRA</h1>
      </div>
      <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
        <div nz-row class="form-title">
          <div nz-col nzSpan="8">
            <div class="line"></div>
          </div>
          <div nz-col nzSpan="8">
            <p>Change Password</p>
          </div>
          <div nz-col nzSpan="8">
            <div class="line"></div>
          </div>
        </div>
        <div class="errors" *ngIf="hasErrors">
          <nz-alert *ngFor="let error of errors" nzType="error" [nzDescription]="error" nzShowIcon></nz-alert>
        </div>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="currentPasswordTpl">
              <input
                readonly
                onfocus="this.removeAttribute('readonly')"
                autocomplete="off"
                [type]="currentPassword ? 'text' : 'password'"
                nz-input
                formControlName="currentPassword"
                placeholder="Current Password"
              />
            </nz-input-group>
          </nz-form-control>
          <ng-template #currentPasswordTpl>
            <i
              nz-icon
              [nzType]="currentPassword ? 'eye-invisible' : 'eye'"
              (click)="currentPassword = !currentPassword"
            ></i>
          </ng-template>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="passwordVisibleTpl">
              <input
                readonly
                onfocus="this.removeAttribute('readonly')"
                autocomplete="off"
                [type]="passwordVisible ? 'text' : 'password'"
                nz-input
                formControlName="password"
                placeholder="New Password"
              />
            </nz-input-group>
          </nz-form-control>
          <ng-template #passwordVisibleTpl>
            <i
              nz-icon
              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"
            ></i>
          </ng-template>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock" [nzSuffix]="repeatPasswordTpl">
              <input
                readonly
                onfocus="this.removeAttribute('readonly')"
                autocomplete="off"
                [type]="repeatPassword ? 'text' : 'password'"
                nz-input
                formControlName="repeatPassword"
                placeholder="Repeat New Password"
              />
            </nz-input-group>
          </nz-form-control>
          <ng-template #repeatPasswordTpl>
            <i
              nz-icon
              [nzType]="repeatPassword ? 'eye-invisible' : 'eye'"
              (click)="repeatPassword = !repeatPassword"
            ></i>
          </ng-template>
        </nz-form-item>
        <button nz-button class="login-form-button login-form-margin" [nzLoading]="isLoading" [nzType]="'primary'">
          Change Password
        </button>
      </form>
    </div>
  </nz-content>
</nz-layout>
