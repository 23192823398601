<label>
  <b>
    <ng-container *ngIf="field.translationPath; else plainTitle">
      <span [translate]="field.translationPath"></span>
    </ng-container>
    <ng-template #plainTitle> {{ field?.title }} </ng-template>
    <span style="color: red" *ngIf="field?.isRequired && inputMode"> *</span>
  </b>
</label>
<ng-container *ngIf="inputMode">
  <nz-input-group [formGroup]="inputGroup" [ngClass]="{ 'is-disabled': field.disabled }">
    <nz-select
      style="width: 100%"
      (ngModelChange)="handleValueChange($event)"
      [(ngModel)]="field.value"
      [attr.autocomplete]="autoFill"
      [nzPlaceHolder]="field.description"
      [formControlName]="field.name"
      [ngClass]="{
        'has-error': inputIsValid() === false,
        'has-success': inputIsValid() === true
      }"
    >
      <nz-option *ngFor="let option of field.options" [nzValue]="option.value" [nzLabel]="option.label"> </nz-option>
    </nz-select>
  </nz-input-group>
</ng-container>
<div class="field-value" *ngIf="!inputMode">
  <span>{{ field?.value ? field?.value : '-' }}</span>
</div>
<p
  *ngIf="this.inputGroup.controls[this.field.name].dirty && this.inputGroup.controls[this.field.name].invalid"
  translate
>
  {{ field.validationMessage }}
</p>
