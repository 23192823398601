import { Component } from '@angular/core';

@Component({
  selector: 'app-assessment-layout',
  templateUrl: './assessment-layout.component.html',
  styleUrls: ['./assessment-layout.component.scss'],
})
export class AssessmentLayoutComponent {
  constructor() {}
}
